import {
  Module, VuexModule,
  // Action,
} from 'vuex-module-decorators';
// import firebase from 'firebase';
// import { Product } from '@/types';

@Module
export default class Products extends VuexModule {
}
