import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, collection, addDoc, onSnapshot,
  QuerySnapshot, DocumentData, doc, getDoc, deleteDoc, updateDoc, DocumentSnapshot, getDocs,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import {
  getStorage, ref, deleteObject,
} from 'firebase/storage';
import { Apiary, Hive } from '@/types';

@Module
export default class Apiaries extends VuexModule {
  apiariesData: Apiary[] = []
  hivesData: Hive[] = []
  apiariesListener: (() => void) | null = null

  currentApiary: Apiary | null = null
  currentApiaryListener: (() => void) | null = null

  currentApiaryHives: Hive[] | null = null
  currentApiaryHivesListener: (() => void) | null = null

  currentHive: Hive | null = null
  currentHiveListener: (() => void) | null = null

  // currentHiveHoneycombs: Honeycomb[] | null = null
  // currentHiveHoneycombsListener: (() => void) | null = null

  // Tutti gli apiari
  get apiaries(): Apiary[] {
    return Object.values(this.apiariesData);
  }

  // Tutte le arnie
  get hives(): Hive[] {
    return Object.values(this.hivesData);
  }

  // Apiario selezionato
  get apiary(): Apiary | null {
    return this.currentApiary;
  }

  // Arnie dell'apiario selezionato
  get apiaryHives(): Hive[] {
    return this.currentApiaryHives ?? [];
  }

  // Arnia selezionata
  get hive(): Hive | null {
    return this.currentHive;
  }

  // get hiveHoneycombs(): Honeycomb[] {
  //   return this.currentHiveHoneycombs ?? [];
  // }

  @Mutation
  setApiaries({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    console.log('mutations:setApiaries()'); // eslint-disable-line no-console

    const data: Apiary[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Apiary);
      });
    }

    this.apiariesData = data;
  }

  @Mutation
  setHives({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    console.log('mutations:setHives()'); // eslint-disable-line no-console

    const data: Hive[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Hive);
      });
    }

    this.hivesData = data;
  }

  // Aggiunge un elenco di arnie all'elenco globale
  @Mutation
  addHives({ hives }: { hives: Hive[] }) {
    console.log('mutations:addHives()'); // eslint-disable-line no-console

    this.hivesData.push(...hives);
  }

  @Mutation
  setApiariesListener({ listener }: { listener: (() => void) | null }) {
    this.apiariesListener = listener;
  }

  @Mutation
  setCurrentApiaryListener({ listener }: { listener: (() => void) | null }) {
    this.currentApiaryListener = listener;
  }

  @Mutation
  setCurrentApiaryHivesListener({ listener }: { listener: (() => void) | null }) {
    this.currentApiaryHivesListener = listener;
  }

  @Mutation
  setCurrentHiveListener({ listener }: { listener: (() => void) | null }) {
    this.currentHiveListener = listener;
  }

  @Mutation
  setCurrentApiary({ snapshot }: { snapshot: DocumentSnapshot }) {
    console.log('mutations:setCurrentApiary'); // eslint-disable-line no-console

    let apiary: Apiary | null = null;

    if (snapshot && snapshot.exists()) {
      const data = snapshot.data();
      console.log(`mutations:setCurrentApiary Retrieved data: ${JSON.stringify(data)}`); // eslint-disable-line no-console

      apiary = {
        id: snapshot.id,
        enabled: data.enabled as boolean,
        name: data.name as string,
        image: data.image as string,
        headerImage: data.headerImage as string,
        posX: data.posX as number,
        posY: data.posY as number,
        beekeeper: {
          name: data.beekeeper.name as string,
          uid: data.beekeeper.uid as string,
        },
        descr: data.descr as string,
        flora: data.flora,
        hivesCount: data.hivesCount,
        hivesFull: data.hivesCount,
        honey: data.honey,
        honeycombsAvailable: data.honeycombsAvailable,
        honeycombsCount: data.honeycombsCount,
        isFull: data.isFull as boolean,
        location: data.location as string,
        longDescr: data.longDescr as string,
      };
    }

    this.currentApiary = apiary;
  }

  @Mutation
  setCurrentHives({ snapshot }: { snapshot: QuerySnapshot }) {
    console.log('mutations:setCurrentHives'); // eslint-disable-line no-console

    const hives: Hive[] = [];

    if (snapshot) {
      snapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        console.log(`mutations:setCurrentHives Retrieved data: ${JSON.stringify(data)}`); // eslint-disable-line no-console

        hives.push({
          id: docSnapshot.id,
          name: data.name as string,
          image: data.image as string,
          photo: data.photo as string,
          posX: data.posX as number,
          posY: data.posY as number,
          honeycombsAvailable: data.honeycombsAvailable as number,
          honeycombsCount: data.honeycombsCount as number,
          population: data.population as string,
          behavior: data.behavior as string,
          productivity: data.productivity as string,
          owners: (data.owners ?? []) as string[],
        });
      });
    }

    this.currentApiaryHives = hives;
  }

  @Mutation
  setCurrentHive({ snapshot }: { snapshot: DocumentSnapshot }) {
    console.log('mutations:setCurrentHive'); // eslint-disable-line no-console

    let hive: Hive | null = null;

    if (snapshot && snapshot.exists()) {
      const data = snapshot.data();
      console.log(`mutations:setCurrentHive Retrieved data: ${JSON.stringify(data)}`); // eslint-disable-line no-console

      hive = {
        id: snapshot.id,
        name: data.name as string,
        image: data.image as string,
        photo: data.photo as string,
        posX: data.posX as number,
        posY: data.posY as number,
        honeycombsAvailable: data.honeycombsAvailable as number,
        honeycombsCount: data.honeycombsCount as number,
        population: data.population as string,
        behavior: data.behavior as string,
        productivity: data.productivity as string,
        owners: (data.owners ?? []) as string[],
      };
    }

    this.currentHive = hive;
  }

  @Action
  initApiaries() {
    console.log('actions:initApiaries'); // eslint-disable-line no-console
    return this.context.dispatch('updateApiaries');
  }

  @Action
  destroyApiaries() {
    console.log('actions:destroyApiaries'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.apiariesListener) this.apiariesListener();
  }

  @Action({ rawError: true })
  async updateHives() {
    console.log('actions:updateHives'); // eslint-disable-line no-console

    // Svuota l'elenco delle arnie attuali
    this.context.commit('setHives', { snapshot: null });

    const db = getFirestore();

    // Scorre l'elenco degli apiari e aggiunge le sue arnie (hives) all'elenco
    // globale

    // eslint-disable-next-line no-restricted-syntax
    for (const apiary of this.apiaries) {
      // Recupera il riferimento alla sotto-collezione
      const hivesCollectionRef = collection(db, `/apiaries/${apiary.id}/hives`);

      // Recupera l'elenco delle arnie
      // eslint-disable-next-line no-await-in-loop
      const snapshot = await getDocs(hivesCollectionRef);

      if (snapshot) {
        // L'elenco delle arnie è stato recuperato

        const hives: Hive[] = [];

        snapshot.forEach((docSnap) => {
          const docData = docSnap.data();

          hives.push({
            id: docSnap.id,
            ...docData,
          } as Hive);
        });

        this.context.commit('addHives', { hives });
      }
    }
  }

  @Action({ rawError: true })
  async updateApiaries() {
    console.log('actions:updateApiaries'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.apiariesListener) this.apiariesListener();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'apiaries');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (apiaries)'); // eslint-disable-line no-console

      // Memorizza l'elenco di tutte le apiarie di Mielopolis
      this.context.commit('setApiaries', { snapshot });

      // Aggiorna l'elenco di tutte le arnie di Mielopolis
      this.context.dispatch('updateHives');
    });

    this.context.commit('setApiariesListener', { listener });
  }

  @Action({ rawError: true })
  async addApiary({ apiary }: { apiary: Apiary }) {
    console.log('actions:addApiary'); // eslint-disable-line no-console

    const db = getFirestore();

    const collectionRef = collection(db, 'apiaries');

    const newApiary = {
      ...apiary,
    } as Apiary;

    return addDoc(collectionRef, newApiary);
  }

  @Action({ rawError: true })
  async updateApiary({ apiary }: { apiary: Apiary }) {
    console.log('actions:updateApiary'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = apiary;

    const docRef = doc(db, `/apiaries/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  @Action
  async deleteApiary({ apiary }: { apiary: Apiary }) {
    console.log('actions:deleteApiary'); // eslint-disable-line no-console

    const { id } = apiary;

    // Recupera il riferimento all'utente
    const db = getFirestore();
    const docRef = doc(db, `/apiaries/${id}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const snapDoc = await getDoc(docRef);
    const docData = snapDoc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove il file dallo storage
    if (imageName) {
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const imageRef = ref(storageRef, imageName);
      await deleteObject(imageRef);
    }

    // Rimuove l'utente
    return deleteDoc(docRef);
  }

  @Action
  async selectApiary(id: string): Promise<void> {
    console.log('actions:selectApiary'); // eslint-disable-line no-console

    // Smette di rimanere in ascolto per le modifiche all'apiario selezionato
    // precedentemente.
    if (this.currentApiaryListener) this.currentApiaryListener();
    if (this.currentApiaryHivesListener) this.currentApiaryHivesListener();

    const db = getFirestore();

    // Recupera il documento associato alla spedizione
    const apiaryRef = doc(db, `/apiaries/${id}`);

    const currentApiaryListener = onSnapshot(apiaryRef, (snapshot) => {
      console.log('onSnapshot (currentApiaryListener)'); // eslint-disable-line no-console

      this.context.commit('setCurrentApiary', { snapshot });
    });
    this.context.commit('setCurrentApiaryListener', { currentApiaryListener });

    // Recupera il riferimento alla sotto-collezione
    const hivesCollectionRef = collection(apiaryRef, 'hives');

    const currentApiaryHivesListener = onSnapshot(hivesCollectionRef, (snapshot) => {
      console.log('onSnapshot (currentApiaryHivesListener)'); // eslint-disable-line no-console

      this.context.commit('setCurrentHives', { snapshot });
    });
    this.context.commit('setCurrentApiaryHivesListener', { currentApiaryHivesListener });

    return Promise.resolve();
  }

  @Action
  async unselectApiary(): Promise<void> {
    console.log('actions:unselectApiary'); // eslint-disable-line no-console

    // Smette di rimanere in ascolto per le modifiche all'apiario selezionato
    // precedentemente.
    if (this.currentApiaryListener) this.currentApiaryListener();
    if (this.currentApiaryHivesListener) this.currentApiaryHivesListener();

    this.context.commit('setCurrentApiary', { snapshot: null });
    this.context.commit('setCurrentHives', { snapshot: null });

    return Promise.resolve();
  }

  @Action
  async selectHive(id: string): Promise<void> {
    console.log('actions:selectHive id =', id); // eslint-disable-line no-console
    console.log('actions:selectHive this.currentApiary.id =', this.currentApiary?.id); // eslint-disable-line no-console

    // Se l'apiario non è selezionato non va avanti
    if (!this.currentApiary?.id) return Promise.reject();

    // Smette di rimanere in ascolto per le modifiche all'arnia selezionata precedentemente
    if (this.currentHiveListener) this.currentHiveListener();

    const db = getFirestore();

    // Recupera il documento associato all'arnia dell'apiario
    const apiaryRef = doc(db, `/apiaries/${this.currentApiary.id}`);
    const hivesCollectionRef = collection(apiaryRef, 'hives');
    const docRef = doc(hivesCollectionRef, id);

    console.log('actions:selectHive docRef =', docRef); // eslint-disable-line no-console

    const currentHiveListener = onSnapshot(docRef, (snapshot) => {
      console.log('actions:selectHive onSnapshot (currentHiveListener)'); // eslint-disable-line no-console

      console.log('actions:selectHive onSnapshot (currentHiveListener) snapshot.exists() =', snapshot.exists()); // eslint-disable-line no-console

      this.context.commit('setCurrentHive', { snapshot });
    });
    this.context.commit('setCurrentHiveListener', { currentHiveListener });

    return Promise.resolve();
  }

  @Action
  async unselectHive(): Promise<void> {
    console.log('actions:unselectHive'); // eslint-disable-line no-console

    // Smette di rimanere in ascolto per le modifiche all'arnia selezionata
    if (this.currentHiveListener) this.currentHiveListener();

    this.context.commit('setCurrentHive', { snapshot: null });

    return Promise.resolve();
  }

  @Action
  async adopt({ uid, honeycombs }: { uid: string; honeycombs: number }) {
    console.log('actions:adopt'); // eslint-disable-line no-console

    const functions = getFunctions();
    const adopt = httpsCallable(functions, 'adopt');

    const params = {
      uid,
      apiaryId: this.currentApiary?.id,
      hiveId: this.currentHive?.id,
      honeycombs,
    };

    console.log('actions:adopt params =', params); // eslint-disable-line no-console

    const result = await adopt(params);

    console.log('actions:adopt result =', result); // eslint-disable-line no-console

    return Promise.resolve(result.data ?? { hives: 0, honeycombs: 0 });
  }

  @Action({ rawError: true })
  async getApiaryById({ apiaryId }: {
    apiaryId: string;
  }): Promise<Apiary | null> {
    console.log(`actions:getApiaryById(${apiaryId})`); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/apiaries/${apiaryId}`);
    const apiaryDoc = await getDoc(docRef);

    if (!apiaryDoc.exists) return null;

    return {
      id: apiaryDoc.id,
      ...apiaryDoc.data(),
    } as Apiary;
  }

  @Action({ rawError: true })
  async getHiveById({ apiaryId, hiveId }: {
    apiaryId: string; hiveId: string;
  }): Promise<Hive | null> {
    console.log(`actions:getHiveById(${apiaryId}, ${hiveId})`); // eslint-disable-line no-console

    const db = getFirestore();

    const apiaryRef = doc(db, `/apiaries/${apiaryId}`);
    const hivesCollectionRef = collection(apiaryRef, 'hives');
    const docRef = doc(hivesCollectionRef, hiveId);

    const hiveDoc = await getDoc(docRef);

    if (!hiveDoc.exists) return null;

    return {
      id: hiveDoc.id,
      ...hiveDoc.data(),
    } as Hive;
  }
}
