import {
  Module, VuexModule, Action, Mutation,
  // Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
// import firebase from 'firebase';
import { Product } from '@/types';

@Module
export default class Cart extends VuexModule {
  items: Product[] = [];
  // shippingMethod = 'spedizione';
  // coupon: string | null = null;

  // get currentCoupon(): string | null {
  //   return this.coupon;
  // }

  // get itemsWithCoupon(): Product[] {
  //   if (this.coupon && this.coupon === 'fiera2021') {
  //     const result: Product[] = [];

  //     const pushProductWithDiscount = (product: Product, discount: number) => {
  //       const { price, ...data } = product;

  //       result.push({
  //         price: price - discount,
  //         ...data,
  //       });
  //     };

  //     this.items.forEach((item) => {
  //       if (item.code === 'pre2022arnia' && item.price === 15) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+1favo' && item.price === 25) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+2favi' && item.price === 35) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+3favi' && item.price === 45) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+4favi' && item.price === 55) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+5favi' && item.price === 65) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+6favi' && item.price === 75) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+7favi' && item.price === 85) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+8favi' && item.price === 95) {
  //         pushProductWithDiscount(item, 3);
  //       } else
  //       if (item.code === 'pre2022arnia+9favi' && item.price === 105) {
  //         pushProductWithDiscount(item, 3);
  //       } else {
  //         result.push(item);
  //       }
  //     });

  //     return result;
  //   }

  //   return this.items;
  // }

  get cartItems(): Product[] {
    // Se non è una spedizione, non include le spese.
    // if (this.shippingMethod !== 'spedizione') return this.itemsWithCoupon;

    // const shipmentNeeded = this.items
    //   .find((p) => [
    //     'pre2022arnia+1favo',
    //     'pre2022arnia+2favi',
    //     'pre2022arnia+3favi',
    //     'pre2022arnia+4favi',
    //     'pre2022arnia+5favi',
    //     'pre2022arnia+6favi',
    //     'pre2022arnia+7favi',
    //     'pre2022arnia+8favi',
    //     'pre2022arnia+9favi',
    //     'primavera2021-500g',
    //     'estate2021-500g',
    //   ].includes(p.code));

    // if (!shipmentNeeded) return this.itemsWithCoupon;

    // const shipmentProduct = {
    //   title: 'Spese di spedizione',
    //   code: 'spedizione',
    //   quantity: 1,
    //   price: 5,
    // };

    // return [...this.itemsWithCoupon, shipmentProduct];

    return this.items;
  }

  // Il carrello è vuoto?
  get isCartEmpty(): boolean {
    return this.items.length === 0;
  }

  // Quanti prodotti nel carrello?
  get cartCount(): number {
    let tot = 0;

    this.items.forEach((p) => {
      tot += p.quantity || 0;
    });

    return tot;
  }

  // get getShippingMethod(): string {
  //   return this.shippingMethod;
  // }

  @Mutation
  addProduct({ product, quantity }: { product: Product; quantity: number }) {
    console.log('mutations:cart.addProduct'); // eslint-disable-line no-console

    // Verifica se il prodotto è già nell'elenco
    const existingProductIndex = this.items.findIndex((p) => p.code === product.code);

    if (existingProductIndex > -1 && existingProductIndex in this.items) {
      // Se il prodotto è già in elenco,
      // aggiunge la quantità desiderata
      Vue.set(this.items[existingProductIndex], 'quantity',
        (this.items[existingProductIndex].quantity || 0) + quantity);
    } else {
      // Il prodotto non è in elenco,
      // lo aggiunge
      const newProduct = product;
      newProduct.quantity = quantity;
      this.items.push(product);
    }
  }

  @Mutation
  removeProduct({ code }: { code: string }) {
    console.log('mutations:cart.removeProduct'); // eslint-disable-line no-console

    const products = this.items.filter((p) => p.code !== code);

    this.items = products;
  }

  @Mutation
  updateProductQuantity({ code, quantity }: { code: string; quantity: number }) {
    console.log('mutations:cart.updateProductQuantity'); // eslint-disable-line no-console

    // Verifica se il prodotto è già nell'elenco
    const existingProductIndex = this.items.findIndex((p) => p.code === code);

    if (existingProductIndex > -1 && existingProductIndex in this.items) {
      // Se il prodotto è in elenco,
      // aggiorna la quantità desiderata
      Vue.set(this.items[existingProductIndex], 'quantity', quantity);
    }
  }

  // @Mutation
  // applyCoupon({ coupon }: { coupon: string }) {
  //   console.log('mutations:cart.applyCoupon'); // eslint-disable-line no-console
  //   this.coupon = coupon.toLowerCase();
  // }

  // @Mutation
  // setShippingMethod({ code }: { code: string }) {
  //   console.log('mutations:cart.setShippingMethod'); // eslint-disable-line no-console

  //   this.shippingMethod = code;
  // }

  @Mutation
  resetThrCart() {
    console.log('mutations:resetThrCart'); // eslint-disable-line no-console

    this.items = [];
  }

  @Action
  async addToCart({ product, quantity }: { product: Product; quantity: number }) {
    console.log('actions:cart.addToCart'); // eslint-disable-line no-console

    this.context.commit('addProduct', { product, quantity });

    return true;
  }

  @Action
  async removeFromCart({ code }: { code: string }) {
    console.log('actions:cart.removeFromCart'); // eslint-disable-line no-console

    this.context.commit('removeProduct', { code });

    return true;
  }

  @Action
  async updateQuantity({ code, quantity }: { code: string; quantity: number }) {
    console.log('actions:cart.updateQuantity'); // eslint-disable-line no-console

    this.context.commit('updateProductQuantity', { code, quantity });

    return true;
  }

  // @Action
  // async addCoupon({ coupon }: { coupon: string }) {
  //   console.log('actions:cart.addCoupon'); // eslint-disable-line no-console

  //   if (coupon.toLocaleLowerCase() !== 'fiera2021') return false;

  //   this.context.commit('applyCoupon', { coupon });

  //   return true;
  // }

  // @Action
  // async updateShippingMethod({ code }: { code: string }) {
  //   console.log('actions:cart.updateShippingMethod'); // eslint-disable-line no-console

  //   this.context.commit('setShippingMethod', { code });

  //   return true;
  // }

  @Action
  async resetCart() {
    console.log('actions:cart.resetCart'); // eslint-disable-line no-console

    this.context.commit('resetThrCart');

    return true;
  }
}
