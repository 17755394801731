import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import Map from './modules/map';
import Products from './modules/products';
import Cart from './modules/cart';
import Apiaries from './modules/apiaries';
import Adoptions from './modules/adoptions';
import Dialogs from './modules/dialogs';
import Posts from './modules/posts';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },

  modules: {
    auth: Auth,
    map: Map,
    products: Products,
    cart: Cart,
    apiaries: Apiaries,
    adoptions: Adoptions,
    dialogs: Dialogs,
    posts: Posts,
  },
});
