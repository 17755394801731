import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { getAuth } from 'firebase/auth';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/map',
    redirect: '/',
  },
  {
    path: '/mappa',
    redirect: '/',
  },

  // La mappa degli apiari
  {
    path: '/',
    name: 'Map',
    component: () => import('@/views/Map.vue'),
  },

  // La mappa delle arnie di uno specifico apiario
  {
    path: '/apiario/:id',
    name: 'Apiary',
    component: () => import('@/views/Apiary.vue'),
  },

  {
    path: '/certificato/:userId/:apiaryId/:hiveId',
    name: 'Certificate',
    component: () => import('@/views/Certificate.vue'),
  },

  // Converte il vecchio link per chi ha prenotato l'adozione
  {
    path: '/mappa/:uid',
    redirect: (to) => ({ path: '/', query: { uid: to.params.uid } }),
  },

  // Pagina di login separata dalla mappa (inutilizzata?)
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/map', '/mappa'];
  const authRequired = !publicPages.includes(to.path)
    && !to.path.startsWith('/certificato/')
    && !to.path.startsWith('/apiario/')
    && !to.path.startsWith('/mappa/');

  const auth = getAuth();
  const { currentUser } = auth;

  if (authRequired && !currentUser) {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  // Reset di eventuali dialog aperti
  store.dispatch('closeAllDialogs');

  return next();
});

export default router;
