












































import {
  Vue, Component,
} from 'vue-property-decorator';
import { User } from './types';

@Component({
  components: {
    WelcomeDialog: () => import('@/components/WelcomeDialog.vue'),
    HowDialog: () => import('@/components/HowDialog.vue'),
    AboutDialog: () => import('@/components/AboutDialog.vue'),

    LoginDialog: () => import('@/components/LoginDialog.vue'),
    ResetDialog: () => import('@/components/ResetDialog.vue'),
    SignupDialog: () => import('@/components/SignupDialog.vue'),
    SignupSuccessDialog: () => import('@/components/SignupSuccessDialog.vue'),

    MyCreditsDialog: () => import('@/components/MyCreditsDialog.vue'),
    MyHivesDialog: () => import('@/components/MyHivesDialog.vue'),
    MyNewsDialog: () => import('@/components/MyNewsDialog.vue'),

    UsersAdminDialog: () => import('@/components/UsersAdminDialog.vue'),
    HivesAdminDialog: () => import('@/components/HivesAdminDialog.vue'),
    NewUpdateDialog: () => import('@/components/NewUpdateDialog.vue'),
    AdoptionsAdminDialog: () => import('@/components/AdoptionsAdminDialog.vue'),
    AdoptionUserDetailsDialog: () => import('@/components/AdoptionUserDetailsDialog.vue'),

    BuyCreditsDialog: () => import('@/components/BuyCreditsDialog.vue'),

    HelpHoneycombDialog: () => import('@/components/HelpHoneycombDialog.vue'),
    HelpAdoptionDialog: () => import('@/components/HelpAdoptionDialog.vue'),
    HelpCreditsDialog: () => import('@/components/HelpCreditsDialog.vue'),
  },
})
export default class App extends Vue {
  firstTime = true
  landingUserEmail: string | null = null

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get isSuper(): boolean {
    return this.currentUser.isSuper;
  }

  get isBeekeeper(): boolean {
    return this.isSuper || this.currentUser.isBeekeeper;
  }

  get canAdminUsers(): boolean {
    return this.isSuper || this.currentUser.canAdminUsers;
  }

  get canAdminAdoptions(): boolean {
    return this.isSuper || this.currentUser.canAdminAdoptions;
  }

  mounted() {
    const debugPrefix = 'App: mounted()';

    // Corregge l'altezza della finestra se modificata
    this.$nextTick(() => {
      const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        // eslint-disable-next-line no-console
        console.log('--app-height =', window.innerHeight);
      };
      window.addEventListener('resize', appHeight);
      appHeight();
    });

    setTimeout(async () => {
      console.log(`${debugPrefix} - this.$route.query =`, this.$route.query); // eslint-disable-line no-console

      // Se l'utente ha usato un link (per accedere) col suo UID nell'URL...
      if (this.landingUserEmail === null && 'uid' in this.$route.query) {
        // Recupera l'email associata
        const result = await this.$store.dispatch('getLandingUserEmail', this.$route.query.uid);
        console.log(`${debugPrefix} - result =`, result); // eslint-disable-line no-console
        this.landingUserEmail = result;

        // Mostra il dialog per effettuare il login
        if (!this.isLoggedIn) this.$store.dispatch('openDialog', 'login');
      }

      // Se è la prima volta che viene caricata la pagina,
      // l'utente non è loggato e non si sta accedendo tramite URL con UID...
      if (this.firstTime
        && !this.isLoggedIn
        && !this.landingUserEmail
        && this.$router.currentRoute.name === 'Map') {
        // Mostra il dialog di benvenuto
        this.$store.dispatch('openDialog', 'welcome');
      }
      this.firstTime = false;
    }, 2000);
  }
}
