import { DialogName, UserDetails } from '@/types';
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';

@Module
export default class Dialogs extends VuexModule {
  dialogs = {
    // Dialog introduttivi
    welcome: false,
    how: false,
    about: false,

    // Dialog per il login e la registrazione
    login: false,
    reset: false,
    signup: false,
    signupSuccess: false,

    // Dialog dell'utente
    myCredits: false,
    myHives: false,
    myNews: false,

    // Dialog di gestione
    usersAdmin: false,
    addUser: false,
    hivesAdmin: false,
    hiveDetails: false,
    newUpdate: false,
    adoptionsAdmin: false,
    adoptionUserDetails: false,

    // Dialog per l'adozione
    apiary: false,
    hive: false,
    adoptSuccess: false,
    adoptError: false,

    // Dialog per l'acquisto
    buyCredits: false,

    // Dialog di aiuto
    helpHoneycomb: false,
    helpAdoption: false,
    helpCredits: false,
  };

  // Dialog introduttivi
  get welcomeDialog(): boolean { return this.dialogs.welcome; }
  get howDialog(): boolean { return this.dialogs.how; }
  get aboutDialog(): boolean { return this.dialogs.about; }

  // Dialog per il login e la registrazione
  get loginDialog(): boolean { return this.dialogs.login; }
  get resetDialog(): boolean { return this.dialogs.reset; }
  get signupDialog(): boolean { return this.dialogs.signup; }
  get signupSuccessDialog(): boolean { return this.dialogs.signupSuccess; }

  // Dialog dell'utente
  get myCreditsDialog(): boolean { return this.dialogs.myCredits; }
  get myHivesDialog(): boolean { return this.dialogs.myHives; }
  get myNewsDialog(): boolean { return this.dialogs.myNews; }

  // Dialog di gestione
  get usersAdminDialog(): boolean { return this.dialogs.usersAdmin; }
  get addUserDialog(): boolean { return this.dialogs.addUser; }
  get hivesAdminDialog(): boolean { return this.dialogs.hivesAdmin; }
  get hiveDetailsDialog(): boolean { return this.dialogs.hiveDetails; }
  get newUpdateDialog(): boolean { return this.dialogs.newUpdate; }
  get adoptionsAdminDialog(): boolean { return this.dialogs.adoptionsAdmin; }
  get adoptionUserDetailsDialog(): boolean { return this.dialogs.adoptionUserDetails; }

  // Dialog per l'adozione
  get apiaryDialog(): boolean { return this.dialogs.apiary; }
  get hiveDialog(): boolean { return this.dialogs.hive; }
  get adoptSuccessDialog(): boolean { return this.dialogs.adoptSuccess; }
  get adoptErrorDialog(): boolean { return this.dialogs.adoptError; }

  // Dialog per l'acquisto
  get buyCreditsDialog(): boolean { return this.dialogs.buyCredits; }

  // Dialog di aiuto
  get helpHoneycombDialog(): boolean { return this.dialogs.helpHoneycomb; }
  get helpAdoptionDialog(): boolean { return this.dialogs.helpAdoption; }
  get helpCreditsDialog(): boolean { return this.dialogs.helpCredits; }

  // Drawer
  drawer = false
  get drawerValue(): boolean { return this.drawer; }

  // Apiario selezionato inizialmente nel dialog 'newUpdate'
  initialApiary: string | null = null
  get updateInitialApiary(): string | null { return this.initialApiary; }

  // Alveare selezionato inizialmente nel dialog 'newUpdate'
  initialHive: string | null = null
  get updateInitialHive(): string | null { return this.initialHive; }

  // Utente selezionato inizialmente nel dialog 'adoptionUserDetails'
  initialUser: UserDetails | null = null
  get dialogInitialUser(): UserDetails | null { return this.initialUser; }

  @Mutation
  setDialog({ name, value }: { name: DialogName; value: boolean }) {
    if (name in this.dialogs) this.dialogs[name] = value;
  }

  @Mutation
  setDrawer({ value }: { value: boolean }) {
    this.drawer = value;
  }

  @Mutation
  setInitialApiary({ value }: { value: string | null }) {
    this.initialApiary = value;
  }

  @Mutation
  setInitialHive({ value }: { value: string | null }) {
    this.initialHive = value;
  }

  @Mutation
  setInitialUser({ value }: { value: UserDetails | null }) {
    this.initialUser = value;
  }

  @Action({ rawError: true })
  openDialog(name: DialogName): void {
    console.log(`actions:openDialog(${name})`); // eslint-disable-line no-console

    this.context.commit('setDialog', {
      name,
      value: true,
    });
  }

  @Action({ rawError: true })
  closeDialog(name: DialogName): void {
    console.log('actions:closeDialog'); // eslint-disable-line no-console

    this.context.commit('setDialog', {
      name,
      value: false,
    });
  }

  @Action({ rawError: true })
  toggleDialog(name: DialogName): void {
    console.log('actions:toggleDialog'); // eslint-disable-line no-console

    const currentValue = this.dialogs[name];

    this.context.commit('setDialog', {
      name,
      value: !currentValue,
    });
  }

  @Action({ rawError: true })
  closeAllDialogs(): void {
    console.log('actions:closeAllDialogs'); // eslint-disable-line no-console

    Object.keys(this.dialogs).forEach((name) => {
      this.context.commit('setDialog', { name, value: false });
    });
  }

  @Action({ rawError: true })
  openDrawer(): void {
    console.log('actions:openDrawer'); // eslint-disable-line no-console

    this.context.commit('setDrawer', {
      value: true,
    });
  }

  @Action({ rawError: true })
  closeDrawer(): void {
    console.log('actions:closeDrawer'); // eslint-disable-line no-console

    this.context.commit('setDrawer', {
      value: false,
    });
  }

  @Action({ rawError: true })
  toggleDrawer(): void {
    console.log('actions:toggleDrawer'); // eslint-disable-line no-console

    this.context.commit('setDrawer', {
      value: !this.drawer,
    });
  }

  @Action({ rawError: true })
  setUpdateInitialApiary(value: string | null): void {
    console.log('actions:setUpdateInitialApiary value =', value); // eslint-disable-line no-console

    this.context.commit('setInitialApiary', {
      value,
    });
  }

  @Action({ rawError: true })
  setUpdateInitialHive(value: string | null): void {
    console.log('actions:setUpdateInitialHive value =', value); // eslint-disable-line no-console

    this.context.commit('setInitialHive', {
      value,
    });
  }

  @Action({ rawError: true })
  setDialogInitialUser(value: UserDetails | null): void {
    console.log('actions:setDialogInitialUser value =', value); // eslint-disable-line no-console

    this.context.commit('setInitialUser', {
      value,
    });
  }
}
