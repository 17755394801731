import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import { User as FirebaseUser } from 'firebase/auth';
import {
  getFirestore, doc, getDoc, DocumentData, onSnapshot, collection, getDocs, setDoc,
} from 'firebase/firestore';
import { User, UserDetails } from '@/types';
import { getFunctions, httpsCallable } from 'firebase/functions';

const generatePassword = (stringLength = 12) => {
  let randomStr = '';
  const characters = '0123456789';
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < stringLength; index++) {
    randomStr += characters.charAt(
      Math.floor(Math.random() * characters.length),
    );
  }
  return randomStr;
};

@Module
export default class Auth extends VuexModule {
  currentUser = {
    firstName: null,
    lastName: null,
    email: null,

    loggedIn: false,
    isSuper: false,
    canAdminUsers: false,
    canAdminAdoptions: false,
    uid: null,

    hivesCredits: 0,
    honeycombsCredits: 0,
    hives: {},

    isBeekeeper: false,

    firebaseUser: null,
  } as User;

  userDetails: UserDetails | null = null
  userDetailsListener: (() => void) | null = null

  get user(): User {
    return this.currentUser;
  }

  @Mutation
  setUserDetailsListener({ listener }: { listener: (() => void) | null }) {
    this.userDetailsListener = listener;
  }

  @Mutation
  setFirebaseUser(user: FirebaseUser) {
    if (user && user.uid) {
      this.currentUser.email = user.email;
      this.currentUser.loggedIn = true;
      this.currentUser.uid = user.uid;
      this.currentUser.firebaseUser = user;
    } else {
      // Manca l'utente Firebase
      this.currentUser.email = null;
      this.currentUser.loggedIn = false;
      this.currentUser.uid = null;
      this.currentUser.firebaseUser = null;

      this.currentUser.isSuper = false;
      this.currentUser.canAdminUsers = false;
      this.currentUser.canAdminAdoptions = false;
      this.currentUser.isBeekeeper = false;
    }
  }

  @Mutation
  setUserDetails(userDetails: UserDetails | null) {
    this.currentUser.firstName = userDetails?.firstName ?? null;
    this.currentUser.lastName = userDetails?.lastName ?? null;

    this.currentUser.hivesCredits = userDetails?.hivesCredits ?? 0;
    this.currentUser.honeycombsCredits = userDetails?.honeycombsCredits ?? 0;
    this.currentUser.hives = userDetails?.hives ?? {};

    this.currentUser.isSuper = userDetails?.isSuper === true;
    this.currentUser.canAdminUsers = userDetails?.canAdminUsers === true;
    this.currentUser.canAdminAdoptions = userDetails?.canAdminAdoptions === true;
    this.currentUser.isBeekeeper = userDetails?.isBeekeeper === true;
  }

  @Action
  selectUser(user: FirebaseUser) {
    const debugPrefix = 'actions:auth/selectUser';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.context.commit('setFirebaseUser', user);

    console.log(`${debugPrefix} - user =`, user); // eslint-disable-line no-console

    if (user && user.uid) {
      // Smette di rimanere in ascolto per le modifiche all'utente selezionato
      // precedentemente.
      if (this.userDetailsListener) this.userDetailsListener();

      // Recupera le informazioni aggiuntive sull'utente
      const db = getFirestore();

      // Recupera il documento associato alla spedizione
      const docRef = doc(db, `/users/${user.uid}`);

      const userDetailsListener = onSnapshot(docRef, (snapshot) => {
        console.log('onSnapshot (userDetailsListener)'); // eslint-disable-line no-console

        let userDetails: DocumentData | null = null;

        if (snapshot.exists()) {
          userDetails = snapshot.data();
        }

        console.log(`${debugPrefix} - userDetails =`, userDetails); // eslint-disable-line no-console

        if (userDetails) {
          this.context.commit('setUserDetails', userDetails);
        }
      });
      this.context.commit('setUserDetailsListener', { userDetailsListener });
    }
  }

  @Action
  async unselectUser(): Promise<void> {
    console.log('actions:unselectUser'); // eslint-disable-line no-console

    // Smette di rimanere in ascolto per le modifiche all'utente selezionato
    // precedentemente.
    if (this.userDetailsListener) this.userDetailsListener();

    this.context.commit('setUserDetails', null);

    return Promise.resolve();
  }

  @Action
  async getLandingUserEmail(id: string): Promise<string | null> {
    console.log('actions:getLandingUserEmail'); // eslint-disable-line no-console

    const db = getFirestore();

    // Recupera il documento associato all'utente
    const docRef = doc(db, `/users/${id}`);
    const docSnapshot = await getDoc(docRef);

    let userDetails: DocumentData | null = null;
    if (docSnapshot.exists()) {
      userDetails = docSnapshot.data();
    }

    return Promise.resolve(userDetails?.email ?? null);
  }

  @Action
  async getUserById({ userId }: {
    userId: string;
  }): Promise<UserDetails | null> {
    console.log('actions:getUserById'); // eslint-disable-line no-console

    const db = getFirestore();

    const userRef = doc(db, `/users/${userId}`);

    const userDoc = await getDoc(userRef);

    if (!userDoc.exists) return null;

    return {
      id: userDoc.id,
      ...userDoc.data(),
    } as UserDetails;
  }

  // Restituisce l'elenco degli utenti
  @Action
  async getUsers(): Promise<UserDetails[]> {
    console.log('actions:getUsers'); // eslint-disable-line no-console

    const db = getFirestore();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'users');
    const snapshot = await getDocs(collectionRef);

    if (snapshot) {
      const data: UserDetails[] = [];

      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as UserDetails);
      });

      return data;
    }

    return [];
  }

  @Action
  async addUser(newUser: UserDetails): Promise<string | void> {
    console.log('actions:addUser'); // eslint-disable-line no-console

    // Genera una password
    const password = generatePassword();

    // Crea l'utente Firebase e ottiene il suo uid
    const functions = getFunctions();
    const createUser = httpsCallable(functions, 'createUser');
    let result: any = null;
    result = await createUser({
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      email: newUser.email,
      password,
      address: newUser.address,
      phone: newUser.phone,
      hivesCredits: newUser.hivesCredits,
      honeycombsCredits: newUser.honeycombsCredits,
    });
    console.log('result =', result); // eslint-disable-line no-console

    const error = result?.data?.error;
    const code = result?.data?.code;
    const uid = result?.data?.uid;

    // Se qualcosa è andato storto,
    // restituisce il codice dell'errore.
    if (error) return Promise.reject(code);

    // Se la registrazione si è completato correttamente,
    // restituisce l'uid dell'utente creato.
    return Promise.resolve(uid);
  }

  @Action({ rawError: true })
  async signupUser({ firstName, lastName, email }:
    { firstName: string; lastName: string; email: string }): Promise<string | void> {
    console.log('actions:signupUser'); // eslint-disable-line no-console

    // Genera una password
    const password = generatePassword();

    // Crea l'utente Firebase e ottiene il suo uid
    const functions = getFunctions();
    const signupUser = httpsCallable(functions, 'signupUser');
    let result: any = null;
    result = await signupUser({
      firstName,
      lastName,
      email,
      password,
    });
    console.log('result =', result); // eslint-disable-line no-console

    const error = result?.data?.error;
    const code = result?.data?.code;
    const uid = result?.data?.uid;

    // Se qualcosa è andato storto,
    // restituisce il codice dell'errore.
    if (error) return Promise.reject(code);

    // Se la registrazione si è completato correttamente,
    // restituisce l'uid dell'utente creato.
    return Promise.resolve(uid);
  }
}
