// Modulo Vuex per la gestione degli aggiornamenti

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import { v4 as uuidv4 } from 'uuid';
import {
  getStorage, ref, deleteObject,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';
import { Post, User } from '@/types';
import {
  addDoc,
  collection, DocumentData, getFirestore, onSnapshot, QuerySnapshot,
} from 'firebase/firestore';
import { getApp } from 'firebase/app';

async function uploadImageToFirestore(image: File, imageName: string, bucket: string) {
  console.log('imageName =', imageName); // eslint-disable-line no-console

  // Ottiene il riferimento allo storage per caricare l'immagine nel bucket dedicato
  const firebaseApp = getApp();
  // eslint-disable-next-line no-underscore-dangle
  const firebaseStorage = getStorage(firebaseApp, bucket);
  const storageRef = ref(firebaseStorage, imageName);

  // Carica l'immagine nello storage
  await uploadBytes(storageRef, image);

  // L'upload è stato completato con successo
  console.log('Upload complete'); // eslint-disable-line no-console

  return Promise.resolve();
}

@Module
export default class Posts extends VuexModule {
  data: Post[] = [];
  listener: (() => void) | null = null;

  get posts(): Post[] {
    return Object.values(this.data);
  }

  @Mutation
  setPosts({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    console.log('mutations:setPosts()'); // eslint-disable-line no-console

    const data: Post[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Post);
      });
    }

    this.data = data;
  }

  @Mutation
  setPostsListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initPosts() {
    console.log('actions:initPosts'); // eslint-disable-line no-console
    return this.context.dispatch('updatePosts');
  }

  @Action
  destroyPosts() {
    console.log('actions:destroyPosts'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updatePosts() {
    console.log('actions:updatePosts'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco dei post "light"
    const collectionRef = collection(db, 'postsLight');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (posts)'); // eslint-disable-line no-console

      this.context.commit('setPosts', { snapshot });
    });

    this.context.commit('setPostsListener', { listener });
  }

  @Action({ rawError: true })
  async uploadImage({ image, bucket }: { image: File; bucket: string }) {
    // eslint-disable-next-line prefer-destructuring
    const currentUser: User = this.context.rootState.auth.currentUser;

    // Prepara il nome del file dell'immagine da caricare aggiungendo un id univoco
    // per evitare conflitti con immagini già presenti nello storage.
    const imageName = `${currentUser.uid}/${uuidv4()}-${image.name}`;

    // Carica l'immagine nello storage e ottiene l'URL di accesso.
    // eslint-disable-next-line no-underscore-dangle
    await uploadImageToFirestore(image, imageName, bucket);

    return Promise.resolve(imageName);
  }

  @Action({ rawError: true })
  async deleteImage({ imageName, bucket }: { imageName: string; bucket: string }) {
    console.log(`actions:deleteImage(${imageName})`); // eslint-disable-line no-console

    // Ottiene il riferimento allo storage per eliminare l'immagine
    const firebaseApp = getApp();
    // eslint-disable-next-line no-underscore-dangle
    const firebaseStorage = getStorage(firebaseApp, bucket);

    // Prepara il nome della versione 1200x1200 dell'immagine
    const imageName1200 = imageName.replace(/(\.[^/.]+)$/, '_1200x1200.jpeg');
    console.log('imageName1200 =', imageName1200); // eslint-disable-line no-console

    // Prepara il nome della versione 300x300 dell'immagine
    const imageName300 = imageName.replace(/(\.[^/.]+)$/, '_300x300.jpeg');
    console.log('imageName300 =', imageName300); // eslint-disable-line no-console

    // Ottiene il riferimento allo storage per eliminare le versioni ridimensionate
    // dell'immagine nel bucket dedicato
    const storageRef1200 = ref(firebaseStorage, imageName1200);
    const storageRef300 = ref(firebaseStorage, imageName300);

    // Elimina le versioni ridimensionate dell'immagine nello storage
    await deleteObject(storageRef1200);
    await deleteObject(storageRef300);

    return Promise.resolve();
  }

  @Action({ rawError: true })
  async addPost({ post, bucket }: { post: Post; bucket: string }) {
    console.log('actions:addPost post =', post); // eslint-disable-line no-console

    const firebaseApp = getApp();
    // eslint-disable-next-line no-underscore-dangle
    const firebaseStorage = getStorage(firebaseApp, bucket);

    let imageUrl1200: string | null = null;
    let imageUrl300: string | null = null;

    if (post.imageName) {
      // Prepara il nome della versione 1200x1200 dell'immagine
      const imageName1200 = post.imageName.replace(/(\.[^/.]+)$/, '_1200x1200.jpeg');
      console.log('imageName1200 =', imageName1200); // eslint-disable-line no-console

      // Prepara il nome della versione 300x300 dell'immagine
      const imageName300 = post.imageName.replace(/(\.[^/.]+)$/, '_300x300.jpeg');
      console.log('imageName300 =', imageName300); // eslint-disable-line no-console

      // Ottiene il riferimento allo storage per caricare le versioni ridimensionate
      // dell'immagine nel bucket dedicato
      const storageRef1200 = ref(firebaseStorage, imageName1200);
      const storageRef300 = ref(firebaseStorage, imageName300);

      // Ottiene l'URL delle versioni ridimensionate dell'immagine
      imageUrl1200 = await getDownloadURL(storageRef1200);
      imageUrl300 = await getDownloadURL(storageRef300);
    }

    const db = getFirestore();
    const collectionRef = collection(db, 'posts');

    const newPost = {
      ...post,
    } as Post;

    delete newPost.imageName;
    if (imageUrl1200) newPost.imageUrl = imageUrl1200;
    if (imageUrl300) newPost.thumbUrl = imageUrl300;

    return addDoc(collectionRef, newPost);
  }

  @Action({ rawError: true })
  async getImageUrl({ imageName, bucket }: { imageName: string; bucket: string }): Promise<string> {
    console.log(`actions:getImageUrl(${imageName})`); // eslint-disable-line no-console

    // Ottiene il riferimento allo storage per eliminare l'immagine
    const firebaseApp = getApp();
    // eslint-disable-next-line no-underscore-dangle
    const firebaseStorage = getStorage(firebaseApp, bucket);

    const imageName1200 = imageName.replace(/(\.[^/.]+)$/, '_1200x1200.jpeg');
    console.log('imageName1200 =', imageName1200); // eslint-disable-line no-console

    // Ottiene il riferimento allo storage per caricare le versioni ridimensionate
    // dell'immagine nel bucket dedicato
    const storageRef1200 = ref(firebaseStorage, imageName1200);

    // Ottiene l'URL delle versioni ridimensionate dell'immagine
    const imageUrl1200 = await getDownloadURL(storageRef1200);

    return Promise.resolve(imageUrl1200);
  }
}
