import {
  Module, VuexModule, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, doc, getDoc, collection, getDocs, updateDoc,
} from 'firebase/firestore';
import { Adoption, ExpiredAdoption } from '@/types';

@Module
export default class Adoptions extends VuexModule {
  @Action({ rawError: true })
  async getAdoptionById({ id }: { id: string }): Promise<Adoption | null> {
    console.log('actions:getAdoptionById'); // eslint-disable-line no-console

    const db = getFirestore();

    const adoptionRef = doc(db, `/adoptions/${id}`);
    const adoptionDoc = await getDoc(adoptionRef);

    if (!adoptionDoc.exists) return null;

    return {
      id: adoptionDoc.id,
      ...adoptionDoc.data(),
    } as Adoption;
  }

  @Action({ rawError: true })
  async getExpiredAdoptionById({ id }: { id: string }): Promise<ExpiredAdoption | null> {
    console.log('actions:getExpiredAdoptionById'); // eslint-disable-line no-console

    const db = getFirestore();

    const adoptionRef = doc(db, `/deletedAdoptions/${id}`);
    const adoptionDoc = await getDoc(adoptionRef);

    if (!adoptionDoc.exists) return null;

    return {
      id: adoptionDoc.id,
      ...adoptionDoc.data(),
    } as ExpiredAdoption;
  }

  // Restituisce l'elenco delle adozioni attive
  @Action
  async getAdoptions(): Promise<Adoption[]> {
    console.log('actions:getAdoptions'); // eslint-disable-line no-console

    const db = getFirestore();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'adoptions');
    const snapshot = await getDocs(collectionRef);

    if (snapshot) {
      const data: Adoption[] = [];

      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Adoption);
      });

      return data;
    }

    return [];
  }

  // Restituisce l'elenco delle adozioni scadute
  @Action
  async getExpiredAdoptions(): Promise<ExpiredAdoption[]> {
    console.log('actions:getExpiredAdoptions'); // eslint-disable-line no-console

    const db = getFirestore();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'deletedAdoptions');
    const snapshot = await getDocs(collectionRef);

    if (snapshot) {
      const data: ExpiredAdoption[] = [];

      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as ExpiredAdoption);
      });

      return data;
    }

    return [];
  }

  // @Action({ rawError: true })
  // async getHiveById({ apiaryId, hiveId }: {
  //   apiaryId: string; hiveId: string;
  // }): Promise<Hive | null> {
  //   console.log(`actions:getHiveById(${apiaryId}, ${hiveId})`); // eslint-disable-line no-console

  //   const db = getFirestore();

  //   const apiaryRef = doc(db, `/apiaries/${apiaryId}`);
  //   const hivesCollectionRef = collection(apiaryRef, 'hives');
  //   const docRef = doc(hivesCollectionRef, hiveId);

  //   const hiveDoc = await getDoc(docRef);

  //   if (!hiveDoc.exists) return null;

  //   return {
  //     id: hiveDoc.id,
  //     ...hiveDoc.data(),
  //   } as Hive;
  // }

  @Action({ rawError: true })
  async updateAdoption({ adoption }: { adoption: Adoption }) {
    console.log('actions:updateAdoption'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = adoption;

    const docRef = doc(db, `/adoptions/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  // Imposta se il miele associato all'adozione è stato consegnato o meno
  @Action({ rawError: true })
  async updateAdoptionDelivery({ adoptionId, delivered }: {
    adoptionId: string; delivered: boolean;
  }): Promise<void> {
    console.log(`actions:updateAdoptionDelivery(${adoptionId}, ${delivered})`); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/adoptions/${adoptionId}`);

    return updateDoc(docRef, {
      delivered,
    });
  }

  // Imposta se il miele associato all'adozione (scaduta) è stato consegnato o meno
  @Action({ rawError: true })
  async updateExpiredAdoptionDelivery({ expiredAdoptionId, delivered }: {
    expiredAdoptionId: string; delivered: boolean;
  }): Promise<void> {
    console.log(`actions:updateExpiredAdoptionDelivery(${expiredAdoptionId}, ${delivered})`); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/deletedAdoptions/${expiredAdoptionId}`);

    return updateDoc(docRef, {
      'data.delivered': delivered,
    });
  }
}
