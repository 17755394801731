/* eslint-disable no-console */

import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import VueMeta from 'vue-meta';
import App from '@/App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.use(VueMeta);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CKEditor = require('@ckeditor/ckeditor5-vue2');

Vue.use(CKEditor);

declare module 'vue/types/vue' {
  interface Vue {
    $firebaseProjectId: string;
    $firebaseFunctionsURL: string;
    $firebaseHostingURL: string;

    $firebasePostsImagesBucket: string;

    $paypalClientId: string;
  }
}

Vue.config.productionTip = false;

let app: Vue;

let firebaseConfig: Record<string, string>;

switch (process.env.NODE_ENV) {
  case 'production':
    firebaseConfig = {
      apiKey: 'AIzaSyCGggWjIEtQo_jqa1hC1foEleA-5dTRhjU',
      authDomain: 'mielopolis-prod.firebaseapp.com',
      databaseURL: 'https://mielopolis-prod.firebaseio.com',
      projectId: 'mielopolis-prod',
      storageBucket: 'mielopolis-prod.appspot.com',
      messagingSenderId: '382583677107',
      appId: '1:382583677107:web:a55d45690ba0a850ee10c6',
      measurementId: 'G-FB46Z0RDKB',
    };
    Vue.prototype.$firebaseProjectId = 'mielopolis-prod';
    Vue.prototype.$firebaseFunctionsURL = 'https://us-central1-mielopolis-prod.cloudfunctions.net/';
    Vue.prototype.$firebaseHostingURL = 'https://mielopolis.it/';

    Vue.prototype.$firebasePostsImagesBucket = 'gs://mielopolis-prod-posts-images';

    Vue.prototype.$paypalClientId = 'AVh6245rdh0Fo5qc2fjna7f8dtX61VAGhnUwH9J2SohsCAKRbujHLnIf1gyg2zoYKVxyp-Pr-owCrapx';
    break;

  default:
    // Di default utilizza la configurazione per l'ambiente 'development'
    firebaseConfig = {
      apiKey: 'AIzaSyA1r3bNDpLXJbBuiUl7P_UI8iIrWSuKpas',
      authDomain: 'mielopolis-dev.firebaseapp.com',
      databaseURL: 'https://mielopolis-dev.firebaseio.com',
      projectId: 'mielopolis-dev',
      storageBucket: 'mielopolis-dev.appspot.com',
      messagingSenderId: '725466984840',
      appId: '1:725466984840:web:e2a36178fd667bf1c6ca3d',
      // measurementId: '',
    };
    Vue.prototype.$firebaseProjectId = 'mielopolis-dev';
    Vue.prototype.$firebaseFunctionsURL = 'https://us-central1-mielopolis-dev.cloudfunctions.net/';
    Vue.prototype.$firebaseHostingURL = 'https://mielopolis.flocca.dev/';

    Vue.prototype.$firebasePostsImagesBucket = 'gs://mielopolis-dev-posts-images';

    Vue.prototype.$paypalClientId = 'AdOZsvcSYq1oX1qp2A_fq9I9pv8_zcFXxCO27lBJbN5AeeZE36GRxEsIk52FpFTsXbs3eEC_i1OOiLf0';
    break;
}

function initFirebase() {
  initializeApp(firebaseConfig);

  // eslint-disable-next-line no-prototype-builtins
  if (firebaseConfig && firebaseConfig.hasOwnProperty('measurementId')) {
    const analytics = getAnalytics();

    Vue.prototype.$analytics = analytics;
  }

  return Promise.resolve();

  // return new Promise((resolve, reject) => {
  //   firebase.firestore().enablePersistence()
  //     .then(resolve)
  //     .catch((err) => {
  //       console.log('firebase.firestore().enablePersistence() error');
  //       console.log(err);

  //       if (err.code === 'failed-precondition') {
  //         reject(err);
  //         // Multiple tabs open, persistence can only be
  //         // enabled in one tab at a a time.
  //       } else if (err.code === 'unimplemented') {
  //         reject(err);
  //         // The current browser does not support all of
  //         // the features required to enable persistence
  //       }
  //     });
  // });
}

// initFirebase
initFirebase()
  .catch((err) => {
    console.log('initFirebase() error');
    console.log(err);
  });

const auth = getAuth();

onAuthStateChanged(auth, async (user) => {
  console.log('onAuthStateChanged()');
  console.log('user =', user);

  store.dispatch('selectUser', user);

  await store.dispatch('initApiaries');
  await store.dispatch('initPosts');

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
