import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';

@Module
export default class Map extends VuexModule {
  ready = false

  get isMapReady(): boolean { return this.ready; }

  @Mutation
  setReady({ value }: { value: boolean }) {
    this.ready = value;
  }

  @Action({ rawError: true })
  mapNotReady(): void {
    console.log('actions:mapNotReady'); // eslint-disable-line no-console

    this.context.commit('setReady', {
      value: false,
    });
  }

  @Action({ rawError: true })
  mapReady(): void {
    console.log('actions:mapReady'); // eslint-disable-line no-console

    this.context.commit('setReady', {
      value: true,
    });
  }
}
